











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

@Component
export default class ProfileCardSquare extends Vue {
  @Prop()
  public readonly profile!: Profile;

  @Prop({ default: true })
  public readonly showContactDetails!: boolean;
}
