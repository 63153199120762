








































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import {
  Office,
  Profile,
  WebsiteProfileDisplayType,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { ProfileFilter } from 'client-website-ts-library/filters';
import ProfileCardsSquare from '@/components/ProfileCardsSquare.vue';
import ProfileCards from '@/components/ProfileCards.vue';
import AgentCardCycler from '@/components/Cycler/AgentCardCycler.vue';
import AgentCardCyclerSquare from '@/components/Cycler/AgentCardSquareCycler.vue';

@Component({
  components: {
    ProfileCardsSquare,
    ProfileCards,
    AgentCardCycler,
    AgentCardCyclerSquare,
  },
})
export default class About extends Mixins(View) {
  public profiles: Profile[] = [];

  public office: Office | null = null;

  mounted() {
    const filter = new ProfileFilter();

    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });

    API.Profiles.Search(filter, true).then((profiles) => {
      this.profiles = profiles;
    });
  }

  get salesAgents(): Profile[] {
    return this.profiles.filter((p) =>
      [
        WebsiteProfileDisplayType.Agent,
        WebsiteProfileDisplayType.Principal,
        WebsiteProfileDisplayType.CommercialAgent,
      ].includes(p.DisplayType),
    );
  }

  get supportAndRentals(): Profile[] {
    return this.profiles.filter(
      (p) =>
        ![
          WebsiteProfileDisplayType.Agent,
          WebsiteProfileDisplayType.Principal,
          WebsiteProfileDisplayType.CommercialAgent,
        ].includes(p.DisplayType),
    );
  }
}
