import { render, staticRenderFns } from "./ProfileCardSquare.vue?vue&type=template&id=2e928544&scoped=true&"
import script from "./ProfileCardSquare.vue?vue&type=script&lang=ts&"
export * from "./ProfileCardSquare.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileCardSquare.vue?vue&type=style&index=0&id=2e928544&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e928544",
  null
  
)

export default component.exports