













import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

import ProfileCardSquare from './ProfileCardSquare.vue';

@Component({
  components: {
    ProfileCardSquare,
  },
})
export default class ProfileCardsSquare extends Vue {
  @Prop({ default: [] })
  public readonly profiles!: Profile[];
}
