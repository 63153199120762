





























import { Vue, Component, Prop } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Profile } from 'client-website-ts-library/types';
import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ProfileCardSquare from '../ProfileCardSquare.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ProfileCardSquare,
  },
})
export default class AgentCardSquareCycler extends Vue {
  @Prop()
  public agentProfiles: Profile[] | null = null;

  @Prop({ default: '' })
  public readonly id!: string;

  public isMobile = window.innerWidth < 768;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1.4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.6,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2.4,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
      },
    },
  ];

  created() {
    window.addEventListener('resize', this.resizeHandler);
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    this.isMobile = window.innerWidth < 768;
  }
}
